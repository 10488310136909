<template>
  <div class="details">
    <Search></Search>
    <!-- 导航栏组件 -->
    <tabNav :store_id="store.store_id"></tabNav>
    <!-- middle-->
    <div class="details-m">
      <!-- 商品展示区 -->
      <div class="goods-main" v-loading="loading">
        <div class="show">
          <div class="big-img">
            <img :src="manzhu" />
          </div>
          <div class="swiper-img">
            <div class="icon" @click="imgmove('left')">
              <i class="fa fa-angle-left fa-3x"></i>
            </div>
            <div class="imgulman">
              <ul class="imgul" ref="barparent">
                <li
                  class="item"
                  :class="item.sec ? 'secitem' : 'noitem'"
                  v-for="(item, index) in imglist"
                  :key="index"
                >
                  <img :src="item.url" @mouseenter="changezhutu(index)" />
                </li>
              </ul>
            </div>
            <div class="icon" @click="imgmove('right')">
              <i class="fa fa-angle-right fa-3x"></i>
            </div>
          </div>
        </div>
        <div class="text-wrapper">
          <div class="title">
            {{ info.goods_name }}
          </div>
          <div class="price-img">
            <div class="pro-tab">
              <span class="text">商城价:</span>
              &nbsp; &nbsp;
              <!-- <span class="pro-money">¥{{ price }}</span> -->
              <span class="pro-money">待定</span>
            </div>
            <div class="p-tab">
              <span class="text">原价:</span>
              &nbsp; &nbsp; ¥{{ originalprice }}
            </div>
            <!-- 累计销量 -->
            <div class="sales">累计销量 {{ info.totalsales }}</div>
          </div>
          <!-- 规格 -->
          <div class="attr mt20" v-for="(item, index) in attrList" :key="index">
            <div class="attr_name">{{ item.name }}</div>
            <div class="attr_val">
              <div
                :class="[
                  valc.cheack == 2 ? 'opt' : '',
                  valc.cheack == 3 ? 'disabled' : '',
                  valc.cheack == 1 ? 'attr_val_li' : '',
                ]"
                v-for="(valc, ic) in item.val"
                :key="ic"
                @click="Onattr(item.name, ic, valc)"
              >
                {{ valc.name }}
              </div>
            </div>
          </div>

          <!-- 数量 -->
          <div class="s-num">
            <span>数量</span>
            <el-input-number
              v-model="num"
              :min="1"
              class="ml15 mr15"
            ></el-input-number>
            <span>库存{{ stock }}件</span>
          </div>
          <!-- 按钮-->
          <div>
            <button
              type="button"
              class="elbutton"
              :class="[cartcheack ? 'buy-btn' : 'no-btn']"
              @click="Buynow()"
            >
              <span><i class="fa fa-shopping-bag"></i> 立即购买</span>
            </button>
            <button
              type="button"
              class="elbutton"
              :class="[cartcheack ? 'cart-btn' : 'no-cbtn']"
              @click="addCart()"
            >
              <span><i class="fa fa-shopping-cart"></i> 加入购物车</span>
            </button>
          </div>
        </div>
      </div>
      <!--收藏商品  -->
      <div class="mt10 collect">
        <i class="fa fa-star-o fa-lg opt"></i>
        <div>收藏商品</div>
      </div>
      <!-- 商品详情 -->
      <div class="goods-d">
        <!-- 店铺信息 -->
        <div class="store-info">
          <div class="store-title">
            <div class="t-header">
              <div class="t-text">
                <a href="#">{{ store.store_name }}</a>
              </div>
              <div class="t-icon">
                <a href="#">
                  <i class="fa fa-comments fa-lg"></i>
                </a>
              </div>
            </div>
            <ul class="t-main">
              <li class="t-inStore t-item">
                <a :href="'/store/home?id=' + store.store_id"
                  ><i class="fa fa-home fa-lg"></i>进店逛逛</a
                >
              </li>
              <li class="t-store t-item">
                <a><i class="fa fa-star fa-lg"></i>关注店铺</a>
              </li>
            </ul>
          </div>
          <ul class="store-rem mt20">
            <li
              class="rem-item"
              :class="[is_hot == 1 ? 'current' : '']"
              @click="is_hot = 1"
            >
              店铺热销
            </li>
            <li
              class="rem-item"
              :class="[is_hot == 2 ? 'current' : '']"
              @click="is_hot = 2"
            >
              热门关注
            </li>
          </ul>
          <div class="goods-rem" v-if="is_hot == 1">
            <div
              class="d-main"
              v-for="(item, index) in store.hlist"
              :key="index"
              @click="togoods(item.id)"
            >
              <div class="m-icon">
                <div class="i-circle">
                  <span>{{ index + 1 }}</span>
                </div>
              </div>
              <div class="m-img">
                <img :src="item.img" alt="#" />
              </div>
              <div class="m-title">
                <a>{{ item.goods_name }}</a>
              </div>
              <div class="m-footer mt10">
                <div class="f-person">{{ item.browse }}人关注</div>
                <div class="f-price">
                  <strong>￥{{ item.price }}</strong>
                </div>
              </div>
            </div>
          </div>

          <div class="goods-rem" v-if="is_hot == 2">
            <div
              class="d-main"
              v-for="(item, index) in store.glist"
              :key="index"
              @click="togoods(item.id)"
            >
              <div class="m-icon">
                <div class="i-circle">
                  <span>{{ index + 1 }}</span>
                </div>
              </div>
              <div class="m-img">
                <img :src="item.img" />
              </div>
              <div class="m-title">
                <a>{{ item.goods_name }}</a>
              </div>
              <div class="m-footer mt10">
                <div class="f-person">{{ item.collect }}人关注</div>
                <div class="f-price">
                  <strong>￥{{ item.price }}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 商品详情介绍 -->
        <div class="store-d">
          <div class="d-header">
            <div class="d-total">
              <div
                class="d-item"
                :class="[is_show == 1 ? 'current' : '']"
                @click="is_show = 1"
              >
                商品详情
              </div>
              <div
                class="d-item"
                :class="[is_show == 2 ? 'current' : '']"
                @click="is_show = 2"
              >
                累计评价(5万+)
              </div>
            </div>
          </div>
          <!-- 累计评价 -->
          <div class="d-comment" v-if="is_show == 2">
            <div class="d-main mt15">
              <div class="m-good">
                <div class="g-percent">100<span>%</span></div>
                <div class="g-text">好评率</div>
              </div>
              <div class="m-comment">
                <ul class="m-ul">
                  <li class="m-item current">全部(0)</li>
                  <li class="m-item">好评(0)</li>
                  <li class="m-item">中评(0)</li>
                  <li class="m-item">差评(0)</li>
                </ul>
              </div>
            </div>
            <div class="row"></div>
            <goods-evaluation @commentNum="setCommentNum"></goods-evaluation>
            <!-- 暂无评论 -->
            <div class="c-comment" v-if="this.comments == 0">
              <div class="no-comment">
                <div class="c-img">
                  <img src="../../assets/images/noEvaluate..png" alt="#" />
                </div>
              </div>
            </div>
          </div>
          <div class="d-goodinfo" v-if="is_show == 1" v-html="info.info"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Search from '../../components/search.vue'
  import { getGoodsInfo } from '@/api/goods'
  import { AddCart } from '@/api/cart'
  import goodsEvaluation from './goodsEvaluation.vue'
  import tabNav from '../../components/tabNav.vue'

  export default {
    name: "goodsDetails",
    components: {
      goodsEvaluation,
      tabNav,
      Search
    },
    data() {
      return {
        id: 0,
        store: [], // 门店信息
        num: 1, // 购物车数量
        is_hot: 1, // 热销商品/关注商品切换
        is_show: 1, // 详情/评价切换
        info: [], // 商品详情
        attrList: [], // 规格列表
        spenlist: [], // 货品列表
        specificationImg: [], // 图片列表
        imglist: [], // 轮播图列表
        manzhu: '', // 主图
        spenlistId: 0, // 当前货品ID
        spenattr: [], // 当前货品规格书组
        stock: 0, // 当前或品库存
        price: 0, // 当前货品价格
        originalprice: 0, // 当前货品原价
        cartcheack: false, // 购物车可选状态
        loading: false,
        comments: 0
      }
    },
    watch: {
      num() { // 数量是否是否可加入购物车
        if (this.spenlistId > 0 && this.stock > 0 && this.info.isStatus == 2 && this.info.isDel == 1 && this.num <= this.stock) {
          return this.cartcheack = true;
        }
        // this.cartcheack = false;
      },
      spenlistId() { // 货品是否可加入购物车
        if (this.spenlistId > 0 && this.stock > 0 && this.info.isStatus == 2 && this.info.isDel == 1 && this.num <= this.stock) {
          return this.cartcheack = true;
        }
        // this.cartcheack = false;
      },
    },
    methods: {
      setCommentNum(num) {
        this.comments = num
        console.log(num)
      },
      togoods(id) {
        let routeUrl = this.$router.resolve({
          path: "/store/goods",
          query: { id: id }
        });
        window.open(routeUrl.href, '_blank');
      },
      Buynow() {
        if (this.cartcheack == false) return false;
        let routeUrl = this.$router.resolve({
          path: "/home/buynow",
          query: { id: this.id, productid: this.spenlistId, num: this.num }
        });
        window.open(routeUrl.href, '_blank');
      },
      async addCart() { // 加入购物车
        this.loading = true
        if (this.cartcheack == false) return false;
        const { data: res } = await AddCart({
          goodid: this.id,
          num: this.num,
          spenlistId: this.spenlistId,
          Store_id: this.info.store_id,
          specattr: this.spenattr
        });
        if (res.status == 90000) { this.$router.push('/home/error'); return false; }
        if (res.message == 'ok') { // 添加成功跳转至成功页面
          let routeUrl = this.$router.resolve({ path: "/home/addToCart", query: { id: this.id, num: this.num } });
          window.open(routeUrl.href, '_blank');
        } else if (res.message == 'error') {
          this.$message(res.result[0]);
        }
        this.loading = false
      },
      async getinfo() { // 获取商品详情信息,初始化数据
        this.loading = true
        const { data: res } = await getGoodsInfo({ id: this.id });
        if (res.status == 90000) { this.$router.push("/home/error?mes=您访问的商品不存在或已经下架"); return false; }
        var tem = res.result;
        this.info = tem.info;
        this.store = tem.store;
        this.spenlist = tem.spenlist;
        this.specificationImg = tem.specificationImg;

        for (var i = 0; i < tem.specificationImg[0].imgs.length; i++) { // 当前轮数据
          this.imglist.push({ sec: i == 0 ? false : true, url: tem.specificationImg[0].imgs[i] })
        }
        var arr = new Array();
        for (var i = 0; i < tem.attrlist.length; i++) {
          var tarr = [];
          for (var j = 0; j < tem.attrlist[i].val.length; j++) {
            tarr.push({ name: tem.attrlist[i].val[j], cheack: 1 });
          }
          this.attrList.push({ 'name': tem.attrlist[i].name, 'val': tarr })
        }
        this.manzhu = this.imglist[0].url;

        if (this.info.specification == 2) { // 多规格商品默认货品设置
          for (var i = 0; i < this.spenlist.length; i++) {
            if (parseInt(this.spenlist[i].stock) > 0) {
              this.spenattr = this.spenlist[i].attr;
              this.spenlistId = this.spenlist[i].id;
              this.price = this.spenlist[i].price;
              this.originalprice = this.spenlist[i].originalprice;
              break;
            }
          }
          if (this.spenattr.length > 0) {
            for (var i = 0; i < this.spenattr.length; i++) {
              var name = this.spenattr[i];
              for (var item in this.attrList) {
                for (var j = 0; j < this.attrList[item].val.length; j++) {
                  if (this.attrList[item].val[j].name == name) {
                    this.attrList[item].val[j].cheack = 2; // 设置默认规格为已点击状态
                  }
                }
              }
              this.judge();
            }
            this.specId();
          }
        } else { // 单规格商品
          if (parseInt(this.spenlist[0].stock) > 0) {
            this.stock = this.spenlist[0].stock;
            this.spenlistId = this.spenlist[0].id;
          }
          this.spenattr = this.spenlist[0].attr;
          this.price = this.spenlist[0].price;
          this.originalprice = this.spenlist[0].originalprice;
        }
        this.loading = false
      },
      Onattr(name, index, attr) { // 规格点击函数
        if (parseInt(this.info.rotation) == 2 && name == this.info.zhugui) { // 轮播图数组更换
          this.imglist = new Array();
          for (var j = 0; j < this.specificationImg[index].imgs.length; j++) {
            this.imglist.push({ sec: j == 0 ? false : true, url: this.specificationImg[index].imgs[j] })
          }
          this.manzhu = this.imglist[0].url;
        }

        if (attr.cheack == 3) return false;

        for (var item in this.attrList) { // 规格按钮状态变化
          if (this.attrList[item].name == name) { // 寻找同级
            for (var i = 0; i < this.attrList[item].val.length; i++) {
              if (this.attrList[item].val[i].name == attr.name) { // 如果点击的是自己则反转
                var cheack = this.attrList[item].val[i].cheack;
                this.attrList[item].val[i].cheack = cheack == 1 ? 2 : 1;
              } else {
                if (this.attrList[item].val[i].cheack != 3)
                  this.attrList[item].val[i].cheack = 1;
              }
            }
          }
        }
        this.judge();
        this.specId();
      },
      specId() { // 货品ID确定 库存价格确定 多规格商品才能使用
        var yestem = new Array(); // 已选规格值
        for (var item in this.attrList) { // 收集已选中的规格
          var cheack = true;
          for (var i = 0; i < this.attrList[item].val.length; i++) {
            if (this.attrList[item].val[i].cheack == 2) {
              yestem.push(this.attrList[item].val[i].name); // 已选
            }
          }
        }
        if (yestem.length == this.spenlist[0].attr.length) {
          for (var i = 0; i < this.spenlist.length; i++) {
            if (this.InStock(this.spenlist[i].attr, yestem)) {
              if (this.spenlist[i].stock <= 0) {
                this.spenlistId = 0;
              } else {
                this.num = 1;
                this.spenlistId = this.spenlist[i].id; // 此货品存在库存
                this.stock = this.spenlist[i].stock; // 库存
                this.price = this.spenlist[i].price;
                this.originalprice = this.spenlist[i].originalprice;
                this.spenattr = yestem;
              }
              break;
            }
          }
        } else {
          this.num = 1;
          this.stock = 0;
          this.spenlistId = 0;
          this.spenattr = [];
        }
      },
      judge() { // 根据当前已所有已选中的规格 计算没有选择的规格是否需置为不可点击
        var notem = new Array(); // 未选规格值
        var yestem = new Array(); // 已选规格值
        for (var item in this.attrList) { // 收集已选中的规格
          var cheack = true;
          for (var i = 0; i < this.attrList[item].val.length; i++) {
            if (this.attrList[item].val[i].cheack == 2) {
              yestem.push(this.attrList[item].val[i].name); // 已选
              cheack = false;
            }
          }
          if (cheack) { // 未选 获取该该规格下所有规格值
            for (var i = 0; i < this.attrList[item].val.length; i++) {
              notem.push(this.attrList[item].val[i].name);
            }
          }
        }
        for (var item in notem) { // 未选择规格其元素是否可点击
          var product = new Array()
          product.push(notem[item]);
          for (var j = 0; j < yestem.length; j++) { product.push(yestem[j]); }
          if (this.ischeack(product)) { this.setup(notem[item], true) } // 可选
          else { this.setup(notem[item], false); } // 不可选
        }
        var tlist = new Array(); // 存放已验证的按钮
        var alist = this.attrList;
        for (var j = 0; j < yestem.length; j++) { // 已选择规格其元素未被选择的是否可点击
          for (var item in alist) {
            var cheack = false;
            for (var i = 0; i < alist[item].val.length; i++) {
              if (alist[item].val[i].name == yestem[j]) {
                cheack = true;
                break;
              }
            }
            if (cheack) {
              var tem = new Array();
              for (var citem in alist) {
                if (citem != item) {
                  var cheack = false;
                  for (var i = 0; i < alist[citem].val.length; i++) {
                    if (alist[citem].val[i].cheack == 2) cheack = true;
                  }
                  if (cheack) {
                    for (var i = 0; i < this.attrList[citem].val.length; i++) {
                      // 已经选定的按钮与已经置灰的规格按钮无需在判断
                      if (!this.inarray(yestem, this.attrList[citem].val[i].name) && !this.inarray(tlist, this.attrList[citem].val[i].name)) {
                        var product = new Array();
                        product.push(this.attrList[citem].val[i].name)
                        product.push(yestem[j]);
                        if (this.ischeack(product)) this.setup(this.attrList[citem].val[i].name, true); // 可选
                        else {
                          tlist.push(this.attrList[citem].val[i].name); // 已经置灰的规格不可在判断
                          this.setup(this.attrList[citem].val[i].name, false);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      setup(val, type) { // 指定规格 标记可选与不可选
        for (var item in this.attrList) {
          for (var k = 0; k < this.attrList[item].val.length; k++) {
            if (this.attrList[item].val[k].name == val) {
              this.attrList[item].val[k].cheack = type ? 1 : 3;
            }
          }
        }
      },
      ischeack(arr) { // 遍历有库存货品  判断当前规格组合是否可选
        var res = false; // 默认不可选
        for (var i = 0; i < this.spenlist.length; i++) {
          if (this.InStock(this.spenlist[i].attr, arr)) {
            if (this.spenlist[i].stock > 0) res = true; // 此货品存在库存
          }
        }
        return res;
      },
      InStock(arr1, arr2) { // 判断arr1中是否存在arr2中所有值
        var res = true; // 默认有
        for (var i = 0; i < arr2.length; i++) {
          if (!this.inarray(arr1, arr2[i])) { // 只要有一个不存在则当前货品包含当中组合
            return false;
          }
        }
        return res;
      },
      inarray(arr, val) { // 判断arr数组中是否存在val这个值
        for (var i = 0; i < arr.length; i++) {
          if (arr[i] == val) return true;
        }
        return false;
      },
      changezhutu(val) { // 轮播图主图切换
        for (var i = 0; i < this.imglist.length; i++) this.imglist[i].sec = true;
        this.imglist[val].sec = false;
        this.manzhu = this.imglist[val].url;
      },
      imgmove(type) { // 轮播图小图左右按钮
        var div = this.$refs.barparent;
        if (type == 'left') div.scrollTop = 0;
        else div.scrollTop = div.scrollHeight;
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (typeof this.id !== 'number' && isNaN(this.id)) { this.$router.push("/home/error?mes=您访问的商品不存在或已经下架"); return false; }
      this.getinfo()
    }
  }
</script>
<style>
.d-goodinfo p {
  width: 100%;
  height: auto;
  padding: 0px;
  margin: 0px;
}

.d-goodinfo img {
  vertical-align: bottom;
  border: none;
  width: 100% !important;
  height: 100% !important;
  display: block;
  margin: 0;
  padding: 0;
}
</style>
<style lang="less" scoped>
.elbutton {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
}

.buy-btn {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.cart-btn {
  margin-left: 10px;
  background: #e1251b;
  border: 1px solid #e1251b;
  color: #fff;
}

.no-btn {
  background: #eee;
  border: 1px solid #eee;
  color: #fff;
}

.no-cbtn {
  margin-left: 10px;
  background: #eee;
  border: 1px solid #eee;
  color: #fff;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.details {
  padding-bottom: 20px;

  .details-m {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    background: #fff;
    margin-top: 20px;

    .goods-main {
      width: 1000px;
      display: flex;
      justify-content: space-between;

      .show {
        .big-img {
          width: 380px;
          height: 380px;
          border: 1px solid rgba(0, 0, 0, 0.05);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .swiper-img {
          width: 380px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;

          .icon {
            width: 25px;
            height: 70px;
            background: #e4e4e4;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: #fff;
            }
          }
        }

        .imgulman {
          width: 380px;
          position: relative;
          overflow: hidden;

          .imgul {
            position: absolute;
            left: 0;
            top: 0;
            right: -17px;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: scroll;

            .item {
              width: 70px;
              height: 70px;
              margin-left: 10px;
              box-sizing: border-box;
              list-style: none;
              white-space: nowrap;
              display: inline-block;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .secitem {
              border: 2px solid #ccc;
            }

            .noitem {
              border: 2px solid red;
            }
          }
        }
      }

      .text-wrapper {
        width: 620px;
        padding: 0 40px;

        .title {
          width: 100%;
          min-height: 20px;
          height: auto;
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          color: #333;
          padding: 10px 0;
          text-align: left;
        }

        .price-img {
          width: 100%;
          height: 80px;
          padding-left: 10px;
          background: url(../../assets/images/price.png) no-repeat;
          position: relative;

          .pro-tab {
            // width: 200px;
            height: 30px;
            position: absolute;
            left: 5%;
            top: 5px;
          }

          .p-tab {
            width: 200px;
            height: 30px;
            position: absolute;
            left: 5%;
            top: 50px;
          }

          .text {
            font-size: 14px;
            color: #999;
          }

          .pro-money {
            font-size: 30px;
            color: #e1251b;
          }

          .sales {
            position: absolute;
            top: 15px;
            right: 45px;
          }
        }

        .attr {
          display: flex;

          .attr_name {
            min-width: 30px;
            width: auto;
            height: 30px;
            margin-right: 10px;
            line-height: 30px;
          }

          .attr_val {
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
          }

          .attr_val_li {
            min-width: 50px;
            width: auto;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0px 10px;
            border: 2px solid #d3d3d3;
            text-align: center;
            line-height: 30px;
            position: relative;
            cursor: pointer;

            &:hover {
              border: 2px solid #e1251b;
            }
          }

          .opt {
            min-width: 50px;
            width: auto;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0px 10px;
            border: 2px solid #d3d3d3;
            text-align: center;
            line-height: 30px;
            position: relative;
            cursor: pointer;
            background: url("../../assets/images/opt.png") no-repeat;
            background-position: right bottom;
            border: 2px solid #e1251b;
          }

          .disabled {
            min-width: 50px;
            width: auto;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0px 10px;
            border: 2px solid #d3d3d3;
            text-align: center;
            line-height: 30px;
            position: relative;
            cursor: pointer;
            border: 2px dashed #d3d3d3;
            color: #d3d3d3;
          }
        }

        .s-num {
          height: 36px;
          margin: 10px 0px 20px;
          display: flex;
          align-items: center;
        }

        .buy-btn {
          color: #e1251b;
          border: 1px solid #e1251b;
        }

        .cart-btn {
          background: #e1251b;
        }
      }
    }

    .collect {
      height: 20px;
      cursor: pointer;
      display: flex;

      i {
        color: #e1251b;
        margin-right: 6px;
        margin-top: 2px;
      }
    }

    .goods-d {
      min-height: 1000px;
      margin-top: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;

      .store-info {
        width: 232px;
        margin-right: 22px;

        .store-title {
          height: 100px;
          border: 1px solid #d3d3d3;

          .t-header {
            height: 40px;
            background: #f7f7f7;
            border-bottom: 1px solid #d3d3d3;
            display: flex;
            align-items: center;

            .t-text {
              font-size: 15px;
              margin: 0px 15px;

              a {
                color: #666;
              }
            }

            .t-icon {
              margin-top: -2px;

              .fa {
                color: #6ea0fd;
              }
            }
          }

          .t-main {
            display: flex;
            margin: 10px 15px;

            .fa {
              color: #e1251b;
            }

            .t-item {
              width: 90px;
              height: 36px;
              border: 1px solid #d3d3d3;

              display: flex;
              justify-content: center;
              align-items: center;
              background: #f7f7f7;

              a {
                color: #666;
              }
            }

            .t-inStore {
              margin-right: 10px;
            }
          }
        }

        .store-rem {
          height: 38px;
          border: 1px solid #d3d3d3;
          border-bottom: 1px solid #e1251b;
          font-size: 14px;
          background: #f7f7f7;
          display: flex;

          .rem-item {
            width: 50%;
            height: 100%;
            text-align: center;
            line-height: 38px;
            cursor: pointer;
            font-weight: 500;
            color: #666;
          }

          .current {
            background: #e1251b;
            color: #fff;
          }
        }

        .goods-rem {
          min-height: 1000px;
          border: 1px solid #d3d3d3;
          border-top: 1px solid #fff;
          color: #666;

          .d-main {
            margin: 30px 10px;
            position: relative;

            .m-icon {
              position: absolute;
              top: -4px;
              left: 12px;

              .i-circle {
                width: 20px;
                height: 20px;
                background: #e1251b;
                border-radius: 50%;
                color: #fff;
                line-height: 20px;
                text-align: center;
              }
            }

            .m-img {
              width: 160px;
              height: 160px;

              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .m-title {
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            .m-footer {
              display: flex;
              justify-content: space-between;

              .f-price {
                color: #e1251b;
                font-size: 14px;
              }
            }
          }

          flex-wrap: wrap;
          align-content: space-between;
        }
      }

      .store-d {
        width: 946px;

        .d-header {
          height: 38px;
          border: 1px solid #d3d3d3;
          border-bottom: 1px solid #e1251b;
          display: flex;
          justify-content: space-between;
        }

        .d-total {
          .d-item {
            float: left;
            min-width: 120px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            font-size: 14px;
            color: #666;
            cursor: pointer;
          }

          .current {
            background: #e1251b;
            color: #fff;
          }
        }

        .d-comment {
          .d-main {
            height: 106px;
            box-sizing: border-box;
            display: flex;

            .m-good {
              width: 225px;
              height: 106px;
              border-right: 1px solid #d3d3d3;
              position: relative;

              .g-percent {
                padding-left: 40px;
                line-height: 106px;
                font-size: 48px;
                color: #e1251b;

                span {
                  font-size: 24px;
                }
              }

              .g-text {
                color: #666;
                font-size: 14px;
                position: absolute;
                top: 20px;
                right: 40px;
              }
            }

            .m-comment {
              .m-ul {
                height: 106px;
                display: flex;
                align-items: center;

                .m-item {
                  width: 120px;
                  height: 50px;
                  margin-left: 40px;
                  background: #efefef;
                  border-radius: 4px;
                  font-size: 14px;
                  text-align: center;
                  line-height: 50px;
                  color: #666;
                }

                .current {
                  background: #e1251b;
                  color: #fff;
                }
              }
            }
          }

          .row {
            border-bottom: 1px solid #d3d3d3;
            margin: 10px 0px;
          }

          .c-comment {
            height: 165px;
            margin: 50px auto;

            .no-comment {
              display: flex;
              justify-content: center;
              align-content: center;

              .c-img {
                width: 200px;
                height: 162px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .d-goodinfo {
          display: table-cell;
          width: 946px;
          height: auto;
          word-break: break-all;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>