<template>
  <div class="goods-evaluation">
    <div class="e-container" v-for="(item, index) in list" :key="index">
      <!-- 名称 -->
      <div class="c-name">
        <img src="#" alt="" />
        <span>{{ item.user_nickname }}</span>
      </div>
      <div class="c-container">
        <div class="c-content">
          <!-- 评分 -->
          <el-rate
            v-model="item.goods_score"
            disabled
            text-color="#e1251b"
            disabled-void-color="#ddd"
            score-template="{value}"
            :colors="colors"
          >
          </el-rate>
          <!-- 评价内容 -->
          <div class="c-txt">
            {{ item.comment }}
          </div>
          <!-- 图片列表 -->
          <div class="img-list clearfix">
            <li class="l-item" @click="previewImage()">
              <img src="#" alt="" />
            </li>
            <li class="l-item">
              <img src="#" alt="" />
            </li>
          </div>
          <!-- 图片放大预览 -->
          <div class="preview" v-if="preview">
            <div class="preview-img">
              <img :src="item.user_avatar" alt="" />
            </div>
          </div>
          <!-- 产品规格和时间 -->
          <div class="c-attr">
            <span class="a-txt">227升一级能效</span>
            <span>2021-07-31 19:41</span>
          </div>
        </div>
        <div class="c-reply">
          <div class="r-title">
            <span>{{ item.admin_name }}</span>
            回复：
          </div>
          <div class="r-content">
            {{ item.seller_reply_content }}
          </div>
          <div class="r-time">2021.06.08</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getReplyListWay } from '@/api/reply'
  export default {
    name: "goodsEvaluation",
    data() {
      return {
        noreply: '',
        goods_name: '',
        id: '',
        list: [],
        rateValue: 5,
        colors: ["#e1251b", "#e1251b", "#e1251b"],
        src: "",
        preview: false,
        relayNum: 0
      }
    },

    methods: {
      previewImage() {
        this.preview = true
      },
      //商品详情评论
      async getList() {
        const { data: res } = await getReplyListWay({ id: this.id })
        this.list = res.result;
        this.goods_name = res.result.goods_name;
        this.relayNum = res.result.length
        // console.log(this.relayNum)
        this.$emit('commentNum', this.relayNum)
      }
    },
    created() {
      this.id = this.$route.query.id;
      this.getList()
    },



  }
</script>

<style lang="less" scoped>
.goods-evaluation {
  .e-container {
    padding: 10px 20px;
    display: flex;
    border-bottom: 1px solid #ddd;

    .c-name {
      width: 140px;
      height: 25px;

      img {
        width: 25px;
        height: 25px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }

    .c-container {
      width: 806px;

      .c-content {
        padding-bottom: 10px;

        .c-txt {
          padding: 10px 0px;
          font-size: 14px;
          color: #333;
        }

        .img-list {
          box-sizing: border-box;

          .l-item {
            float: left;
            margin-right: 10px;
            border: 1px solid #ddd;
            width: 52px;
            height: 52px;

            img {
              width: 52px;
              height: 52px;
            }
          }
        }

        .preview {
          margin: 10px 0px;

          .preview-img {
            width: 200px;
            height: 400px;

            img {
              width: 200px;
              height: 400px;
            }
          }
        }

        .c-attr {
          color: #666;
          margin-top: 10px;

          .a-txt {
            margin-right: 80px;
          }
        }
      }

      .c-reply {
        color: #e1251b;
        padding-top: 10px;
        border-top: 1px solid #ddd;

        .r-title {
          font-size: 14px;
        }

        .r-content {
          margin: 5px 0px;
          font-size: 13px;
        }

        .r-time {
          color: #666;
        }
      }
    }
  }
}
</style>